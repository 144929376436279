import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "pages";
import About from "pages/About";
import Project from "pages/Project";
import Contact from "pages/Contact";
import Interiors from "pages/Interiors";
import BuildingDesign from "pages/BuildingDesign";
import { Toaster } from "react-hot-toast";
import ProjectDetails from "pages/ProjectDetails";
import NotFound from "pages/NotFound";
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init({
      offset: 200, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: 'ease-in-out', // default easing for AOS animations
      once: true
    });
  }, []);

  return (
    <BrowserRouter>
      <Toaster position="top-center" />
      <Routes>
        <Route index element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects/" element={<Project />} />
        <Route path="/projects/:projectId" element={<ProjectDetails />} />
        <Route path="/interior-design" element={<Interiors />} />
        <Route path="/building-design" element={<BuildingDesign />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;