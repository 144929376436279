import React from "react"
import { IServiceList } from "interfaces"
import clsx from "clsx"

const ServiceList: React.FC<IServiceList> = ({ id, image, pages, title, classNames }) => {
    return (
        <div className={clsx(
            "flex items-center w-full text-left sm:border-neutral-30 space-x-4",
            "py-[11px] px-[10px]",
            pages === 'building' && (id === 3 || id === 4) ? 'sm:border-none' : 'sm:border-b',
            pages === 'interior' && (id === 5 || id === 6) ? 'sm:border-none' : 'sm:border-b',
            classNames
        )} data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
            <img src={`/icons/${pages}/${image}.svg`} className='w-[20px] h-[20px]' alt={title} />
            <p className={clsx(
                "text-primary-main phone:text-[16px] tablet:text-lg phone:text-md text-[20px] font-normal",
                "leading-[34px] phone:leading-[27.2px] tablet:leading-[30.6px]",
                pages == 'building' ? 'max-w-[175px] sm:max-w-[200px] xl:max-w-[220px]' : 'phone:max-w-[380px]',
            )}>{title}</p>
        </div>
    )
}

export default ServiceList