import clsx from "clsx"
import { Fragment, useEffect } from "react"
import Navbar from "components/organism/Navbar"
import Meta from "modules/Meta"
import { LayoutInterface } from "interfaces"
import Footer from "components/organism/Footer"

const PageLayouts = ({ children, classNames, title, description }: LayoutInterface) => {
    return (
        <Fragment>
            <Meta title={title} description={description} />
            <Navbar />
            <div className={clsx(
                "w-full max-w-[1440px] mx-auto overflow-hidden", classNames
            )}>
                {children}
            </div>
            <Footer />
        </Fragment>
    )
}

export default PageLayouts