import MailchimpSubscribe from 'react-mailchimp-subscribe';
import NewsletterModal from 'components/organism/NewsletterModal';

const NewsletterSubscribe = () => {

    const MAILCHIMP_URL = "https://blendedinteriors.us12.list-manage.com/subscribe/post?u=b76c39ace294bb11a69788415&id=bf62a7e800"

    return (
        <MailchimpSubscribe
            url={MAILCHIMP_URL}
            render={ (props) => {
                const { subscribe, status, message } = props || {};
                return (
                    <NewsletterModal
                        status={status}
                        message={message}
                        onValidated={subscribe}
                    />
                )
            }}
        />
    );
};

export default NewsletterSubscribe;