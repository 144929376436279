import { Fragment, useState } from "react"
import PageLayouts from "layouts/PageLayouts"
import { DescriptionHome, SectionTitleHome } from "components/atoms/Text"
import CalltoAction from "components/organism/CalltoAction"
import { Loading } from "components/atoms/Loading"
import NewsletterModal from "components/organism/NewsletterModal"
import NewsletterSubscribe from "containers/Newsletter/NewsletterSubscribe"

const Homepage = () => {
    const [showVideo, setShowVideo] = useState<boolean>(false)
    return (
        <Fragment>
            <PageLayouts title="Homepage" description="Transforming empty space into dream places" classNames="phone:px-6 tablet:px-10 desktop:px-14">
                <NewsletterSubscribe />
                {/* <NewsletterModal /> */}
                <Loading isShow={!showVideo} />
                <video
                    autoPlay loop muted playsInline poster={require("assets/images/hero.webp")}
                    className="object-cover phone:h-[200px] desktop:h-[664px] tablet:h-[472px] w-full"
                    onCanPlay={() => {
                        setTimeout(() => {
                            setShowVideo(true)
                        }, 500)
                    }}
                >
                    <source src={require("assets/videos/marbury.mp4")} />
                </video>
                <div className="w-full phone:py-10 py-20 text-center">
                    <SectionTitleHome classNames="desktop:max-w-[670px] tablet:max-w-[672px] sm:mx-auto -mx-1">Transforming empty spaces into dream places</SectionTitleHome>
                    <div className="phone:max-w-full desktop:max-w-[590px] tablet:max-w-[590px] mx-auto">
                        <DescriptionHome classNames="phone:text-[18px] phone:leading-[30.6px] phone:pt-8 phone:pb-8 tablet:pb-8 pb-8 pt-10">Blended Interiors and Design is a Brisbane based studio that specialises in high end residential projects and boutique commercial fitouts.</DescriptionHome>
                        <DescriptionHome classNames="phone:text-[18px] phone:leading-[30.6px] phone:pb-8 tablet:pb-8 pb-8">We are qualified in both Building Design and Interior Design which makes our business an all inclusive service for our clientele. This enables us to provide a seamless journey for those looking for a beginning to end solution.</DescriptionHome>
                        <DescriptionHome classNames="phone:text-[18px] phone:leading-[30.6px] phone:pb-8 tablet:pb-8 pb-8">Our skilled designers are creative and savvy when it comes to combining your vision and budget. Our studio prides itself on the finer details that make a design aesthetically pleasing and functional.</DescriptionHome>
                        <DescriptionHome classNames="phone:text-[18px] phone:leading-[30.6px]">We work closely with our clients to understand their goals whilst also considering the seamless combination of space, colour, texture and shape for an all encompassing design outcomes.</DescriptionHome>
                    </div>
                </div>
                <CalltoAction />
            </PageLayouts>
        </Fragment>
    )
}

export default Homepage