import { Fragment, useState, useEffect, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Loading } from "components/atoms/Loading"
import axios from "axios"

interface formProps {
    status: any,
    message: any,
    onValidated: any,
}

const NewsletterModal = ({ status, message, onValidated }: formProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const closeModal = () => setIsOpen(false)
    const checkToken = () => {
        const token = window.localStorage.getItem("token")
        if (!token) setTimeout(() => {
            setIsOpen(true)
        }, 3000)
    }
    useEffect(() => {
        checkToken()
    }, [])

    const [email, setEmail] = useState('');

    const handleSubmit = () => {
        const isFormValidated = onValidated({ EMAIL: email });
        return email && email.indexOf("@") > -1 && isFormValidated;
    }

    const handleChangeEmail = (event: any) => {
        setEmail(event.target.value);
    }

    return (
        <Fragment>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[rgba(18, 32, 63, 0.2)] backdrop-blur-sm" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-[560px] transform overflow-hidden rounded-2xl bg-white phone:py-8 phone:px-6 p-8 text-left align-middle transition-all">
                                    <div className="flex phone:flex-col-reverse flex-row md:items-center justify-between">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-heading-5 font-bold leading-[40.8px] text-primary-main"
                                        >
                                            Join our Newsletter
                                        </Dialog.Title>
                                        <svg onClick={closeModal} className="hover:cursor-pointer phone:ml-auto" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 18.5L18 6.5M6 6.5L18 18.5" stroke="#12203F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <div className="mt-2">
                                        <p className="phone:text-[16px] phone:leading-[27.2px] text-lg text-primary-main text-opacity-50 leading-[30.6px]">
                                            Join our newsletter to stay up to date on the latest trends, giveaways and fun design hacks.
                                        </p>
                                    </div>

                                    <div className="w-full mt-5">
                                        <div className="flex phone:flex-col flex-row items-center justify-between gap-4">
                                            <div className="w-full relative">
                                                <input onChange={handleChangeEmail} type="email" name="EMAIL" placeholder="Your email" className="w-full text-[16px] sm:text-[18px] leading-7 py-[13.2px] pr-5 pl-[52px] rounded-full bg-[#F8F8F9] focus:outline-none" />
                                                <svg className="absolute top-[18px] sm:top-[19px] left-5 z-30" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity="0.5">
                                                        <path d="M1.25 7.22498V14.375C1.25 15.038 1.51339 15.6739 1.98223 16.1427C2.45107 16.6116 3.08696 16.875 3.75 16.875H16.25C16.913 16.875 17.5489 16.6116 18.0178 16.1427C18.4866 15.6739 18.75 15.038 18.75 14.375V7.22498L11.31 11.8025C10.916 12.0449 10.4626 12.1732 10 12.1732C9.53745 12.1732 9.08396 12.0449 8.69 11.8025L1.25 7.22498Z" fill="#12203F" />
                                                        <path d="M18.75 5.75667V5.625C18.75 4.96196 18.4866 4.32607 18.0178 3.85723C17.5489 3.38839 16.913 3.125 16.25 3.125H3.75C3.08696 3.125 2.45107 3.38839 1.98223 3.85723C1.51339 4.32607 1.25 4.96196 1.25 5.625V5.75667L9.345 10.7383C9.54198 10.8595 9.76872 10.9237 10 10.9237C10.2313 10.9237 10.458 10.8595 10.655 10.7383L18.75 5.75667Z" fill="#12203F" />
                                                    </g>
                                                </svg>
                                            </div>
                                            <button onClick={handleSubmit} className="w-fit phone:w-full py-4 px-6 rounded-full bg-primary-main text-neutral-10 font-medium text-sm leading-[23.8px]">Subscribe</button>
                                        </div>
                                        <div>
                                            {status === "sending" && (
                                                <div className="text-sm mt-2">
                                                    sending...
                                                </div>
                                            )}
                                            {status === "error" && (
                                                <div
                                                    className="text-sm mt-2 text-red-500"
                                                    dangerouslySetInnerHTML={{ __html: message }}
                                                />
                                            )}
                                            {status === "success" && (
                                                <div
                                                    className="text-sm mt-2 text-green-500"
                                                    dangerouslySetInnerHTML={{ __html: message }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </Fragment>
    )
}

export default NewsletterModal