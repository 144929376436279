import clsx from "clsx"
import { TextInterface } from "interfaces"

export const SectionTitleHome = ({ children, classNames }: TextInterface) => {
    return <h1 className={clsx(
        "font-normal text-primary-main phone:text-heading-4 phone:leading-[36.4px] desktop:text-heading-1 tablet:text-[56px] tablet:leading-[72.8px] desktop:leading-[72.8px]",
        classNames
    )}>{children}</h1>
}

export const SectionTitle = ({ children, classNames }: TextInterface) => {
    return <h1 className={clsx(
        "font-normal text-primary-main phone:text-heading-4 phone:leading-[36.4px] desktop:text-heading-1 tablet:text-[48px] tablet:leading-[62.4px] desktop:leading-[72.8px]",
        classNames
    )}>{children}</h1>
}

export const HeadingSection = ({ children, classNames }: TextInterface) => {
    return <h1 className={clsx(
        "font-normal text-primary-main phone:text-heading-5 phone:leading-[31.2px] tablet:text-heading-3 tablet:leading-[52px] text-heading-2 leading-[62.4px]",
        classNames
    )}>{children}</h1>
}

export const ContentSection = ({ children, classNames }: TextInterface) => {
    return <p className={clsx(
        "mt-6 text-primary-main text-justify phone:text-[14px] phone:leading-[23.8px] tablet:text-[16px] tablet:leading-[27.2px] text-lg font-normal leading-[30.6px]",
        classNames
    )}>{children}</p>
}

export const Description = ({ children, classNames }: TextInterface) => {
    return <p className={clsx(
        "phone:text-[18px] desktop:leading-[30.6px] phone:leading-[30.6px] tablet:text-[16px] desktop:text-lg font-normal text-neutral-100 leading-text tablet:leading-[30.6px]",
        classNames
    )}>{children}</p>
}

export const DescriptionHome = ({ children, classNames }: TextInterface) => {
    return <p className={clsx(
        "phone:text-[18px] desktop:leading-[30.6px] phone:leading-[30.6px] tablet:text-[18px] desktop:text-lg font-normal text-neutral-100 leading-text tablet:leading-[30.6px]",
        classNames
    )}>{children}</p>
}

export const AuthorText = ({ children, classNames }: TextInterface) => {
    return <h1 className={clsx(
        "font-bold text-heading-5 text-primary-main leading-[31.2px]",
        classNames
    )}>{children}</h1>
}