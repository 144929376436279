import { Helmet } from "react-helmet";
import { MetaFunction } from "interfaces";

const Meta = ({ title, description }: MetaFunction) => {
    return (
        <Helmet>‍
            <title>{title} | Blended Interiors</title>
            <link rel="icon" href="/icons/general/logo.svg" media="logo" />
            <meta httpEquiv="X-UA-Compatible" content="IE=Edge, chrome" />
            <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />‍
            <meta name="keywords" content="Blended Interiors" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="description" content={description} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="https://blended-interiors.vercel.app/" />
            <meta name="twitter:creator" content="Blended Interiors - Transforming empty space into dream places" />
            <meta name="twitter:title" content="Blended Interiors - Transforming empty space into dream places" />
            <meta name="twitter:description" content="Transforming empty space into dream places" />
            <meta name="twitter:image" content="Blended Interiors" />
            <meta property="og:title" content="Blended Interiors" />
            <meta property="og:description" content="Transforming empty space into dream places" />
            <meta property="og:image" content="Blended Interiors" />
            <meta property="og:url" content="https://blended-interiors.vercel.app/" />
            <meta property="og:site_name" content="Blended Interiors" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="Furniture" />
        </Helmet>
    );
};

export default Meta;