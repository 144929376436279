import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination, EffectFlip, EffectFade } from "swiper";
import "swiper/css";
import 'swiper/css/pagination';
import "swiper/css/effect-flip"
import "swiper/css/effect-fade"
import LazyImage from "components/LazyImage";
import "react-lazy-load-image-component/src/effects/blur.css"

interface currentProjectProps {
	currentProject: any
	isOpenLightGallery: any
	handleIsOpenLightGallery: any
}

const ProjectShowModal = ({ currentProject, isOpenLightGallery, handleIsOpenLightGallery }: currentProjectProps) => {
	const swiper = useSwiper()

	return (
		<>
			<div className="relative z-10 overflow-hidden">
				<div className="fixed inset-0 bg-[rgba(18,_18,_18,_0.65)] transition-opacity backdrop-blur-[6px]"></div>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
						<div className="relative transform rounded-lg text-left transition-all sm:my-8 w-full md:max-w-xl lg:max-w-[860px] xl:max-w-[1160px]">
							<div className="w-full flex items-center justify-between mb-6 md:mb-8">
								<div className="">
									<div className="jail-app-left-side-fractions-2 font-normal text-sm text-[rgba(255,_255,_255,_0.56)] leading-[19.6px]"></div>
								</div>
								<button type="button" className="text-[rgba(255,_255,_255,_0.56)] hover:text-white" onClick={() => handleIsOpenLightGallery(false, 0)}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
									</svg>
								</button>
							</div>
							<Swiper
								loop={true}
								speed={1200}
								effect={"fade"}
								navigation={{
									nextEl: ".next",
									prevEl: ".prev"
								}}
								centeredSlides={true}
								slidesPerView={1}
								pagination={{
									el: '.jail-app-left-side-fractions-2',
									type: 'custom',
									renderCustom: function (swiper, current, total) {
										return `0${current} of 0${total}`;
									}
								}}
								modules={[Pagination, Navigation, EffectFlip, EffectFade]}
								initialSlide={isOpenLightGallery.indexSlideOpen}
								className="w-full relative h-[414.3px] lg:h-[560px] px-10 transition-all duration-200"
							>
								{currentProject.detailImage.map((item: any) => (
									<SwiperSlide>
										<LazyImage
											src={`/images/project/detailImage/${item}.jpg`}
											placeholderImage={`/images/project/detailImage/compressed/${item}.webp`}
											alt={item}
											classNames="w-full md:w-[860px] xl:w-[1160px] h-[414.3px] lg:h-[560px] mx-auto object-cover" />
									</SwiperSlide>
								))}
							</Swiper>
							<div className="absolute top-1/2 flex justify-between w-full">
								<button onClick={() => swiper.slidePrev()} className="ml-3 md:-ml-16 xl:-ml-[70px] z-50">
									<img className="w-8 h-8 md:w-[44px] md:h-[44px] prev z-50" src="/icons/general/previous.svg" alt="previous-icon" />
								</button>
								<button onClick={() => swiper.slideNext()} className="mr-3 md:-mr-16 xl:-mr-[70px] z-50">
									<img className="w-8 h-8 md:w-[44px] md:h-[44px] next z-50" src="/icons/general/next.svg" alt="next-icon" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ProjectShowModal