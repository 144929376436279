import { Fragment } from "react"
import PageLayouts from "layouts/PageLayouts"
import CalltoActionLayout from "layouts/CalltoActionLayout"
import CalltoAction from "components/organism/CalltoAction"

const Contact = () => {
    return (
        <Fragment>
            <PageLayouts title="Contact" description="Transforming empty space into dream places">
                <CalltoActionLayout>
                    <CalltoAction />
                </CalltoActionLayout>
            </PageLayouts>
        </Fragment>
    )
}

export default Contact