import clsx from "clsx"
import { Fragment } from "react"
import { LayoutInterface } from "interfaces"

const CalltoActionLayout = ({ children, classNames }: LayoutInterface) => {
    return (
        <Fragment>
            <div className={clsx(
                "w-full phone:px-6 tablet:px-10 desktop:px-14", classNames
            )}>
                {children}
            </div>
        </Fragment>
    )
}

export default CalltoActionLayout