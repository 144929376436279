import { Fragment } from "react"
import PageLayouts from "layouts/PageLayouts"
import CalltoActionLayout from "layouts/CalltoActionLayout"
import LazyImage from "components/LazyImage"
import { SectionTitle, ContentSection, HeadingSection } from "components/atoms/Text"
import CalltoAction from "components/organism/CalltoAction"
import ServiceList from "components/moleculs/ServiceList"
import { designServiceList, designServiceListMobile } from "data"

const BuildingDesign = () => {
    return (
        <Fragment>
            <PageLayouts title="Building Design" description="Transforming empty space into dream places">
                <div className="phone:px-6 tablet:px-10 desktop:px-14 tablet:pb-[72px]" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                    <LazyImage
                        src="/images/building/hero.png"
                        placeholderImage="/images/compressed/building/hero.webp"
                        classNames="object-cover phone:h-[200px] desktop:h-[664px] tablet:h-[472px] w-full"
                        alt="hero-building-design"
                    />
                </div>
                <div className="w-full phone:pt-10 phone:pb-10 tablet:pt-0 tablet:pb-20 desktop:pt-[72px] desktop:pb-20 phone:px-6 tablet:px-10 desktop:px-[140px]">
                    <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                        <SectionTitle classNames="desktop:max-w-[650px] text-center tablet:max-w-[590px] mx-auto tablet:text-[48px] tablet:leading-[62.4px]">Building Design <br /> Service</SectionTitle>
                    </div>
                    <div className="my-14 sm:grid gap-x-20 grid-cols-2 hidden">
                        {designServiceList.map((item: any) => (
                            <ServiceList id={item.id} title={item.title} pages="building" image={item.image} />
                        ))}
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-x-0 sm:hidden divide-y divide-neutral-30 sm:divide-y-0">
                        {designServiceListMobile.map((item: any) => (
                            <ServiceList id={item.id} title={item.title} pages="building" image={item.image} />
                        ))}
                    </div>
                </div>
                <div className="w-full phone:mt-0 phone:mb-12 tablet:mt-0 tablet:mb-20 desktop:mt-0 desktop:mb-20 phone:px-6 tablet:px-10 desktop:px-[140px]">
                    {/* Initial Design */}
                    <div className="w-full flex flex-col phone:flex-col-reverse md:flex-row justify-between">
                        <div className="w-full tablet:w-[432px] desktop:w-[502px] phone:mt-6">
                            <div data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">
                                <HeadingSection>Initial Design Consultation <br />(2 Hour Duration)</HeadingSection>
                            </div>
                            <div data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">
                                <ContentSection classNames="mt-4 sm:mt-6">Ready to renovate or build? During the two hour design consultation we will explore your design brief with you to help identify your project goals and how this could be achieved within your budget. We will also highlight areas of interest that you may not have considered previously, as well as identify important factors that might dictate the design outcome. Whilst we love to help everyone, our design process is tailored towards medium to large scale renovation projects and new builds.</ContentSection>
                                <ContentSection classNames="mt-3.5 sm:mt-4">For a rough guide to help gauge your project budget we suggest a minimum figure of $3000 per square metre and upwards. There are a lot of key factors that come into play when discussing the design brief so we highly recommend meeting at the proposed site in order to provide you with appropriate advice on your design vision.</ContentSection>
                                <ContentSection classNames="mt-3.5 sm:mt-4">Design Consultations start from $440+GST</ContentSection>
                            </div>
                        </div>
                        <div className="w-full tablet:w-[432px] desktop:w-[502px]" data-aos="fade-left" data-aos-delay="200" data-aos-duration="1000">
                            <LazyImage
                                src="/images/building/initial-design.png"
                                placeholderImage="/images/compressed/building/initial-design.webp"
                                classNames="w-full object-cover phone:h-[414px] tablet:h-[546px] h-[640px] phone:mt-10 md:mt-0"
                                alt="initial-design"
                            />
                        </div>
                    </div>
                    {/* The Building Design */}
                    <div className="w-full flex flex-col md:flex-row justify-between">
                        <div className="w-full tablet:w-[432px] desktop:w-[502px]" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">
                            <LazyImage
                                src="/images/building/the-design.png"
                                placeholderImage="/images/compressed/building/the-design.webp"
                                classNames="w-full object-cover phone:h-[414px] tablet:h-[546px] h-[636px] mb-6 sm:mb-0 mt-12 tablet:mt-[90px] desktop:mt-[100px]"
                                alt="the-design"
                            />
                        </div>
                        <div className="w-full tablet:w-[432px] desktop:w-[502px] tablet:-mt-[11px] desktop:mt-2">
                            <div data-aos="fade-left" data-aos-delay="100" data-aos-duration="1000">
                                <HeadingSection>The Building Design</HeadingSection>
                            </div>
                            <div data-aos="fade-left" data-aos-delay="200" data-aos-duration="1000">
                                <ContentSection classNames="mt-4 sm:mt-6">All great designs start with a great concept. Through exploring the site, space and building codes our Building Designers will guide you through the design process step by step.</ContentSection>
                                <ContentSection classNames="mt-3.5 sm:mt-4">This process starts with the concept design which is where we explore the feasibility of your project. During this stage we work closely with you to develop a design which meets both your budget and brief. We will communicate openly with you and will have honest conversations to assist you in staying focused on your goals. Once the design has reached a point that you are happy with we recommend seeking feedback and ballpark costings for construction. Following on from this your plans will be detailed to a Design Development issue which is submitted to Council and any other external consultants required.</ContentSection>
                                <ContentSection classNames="mt-3.5 sm:mt-4">All things approved the Working Drawings are released for final tender by your chosen builder. We know this experience can be overwhelming so we try to keep things light and breezy for you while we do the heavy lifting.</ContentSection>
                            </div>
                        </div>
                    </div>
                    {/* A Cohesive Process */}
                    <div className="w-full flex flex-col phone:flex-col-reverse md:flex-row justify-between xl:pb-20">
                        <div className="w-full tablet:w-[432px] desktop:w-[502px] phone:pt-6 tablet:pt-[90px] desktop:pt-[100px]">
                            <div data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">
                                <HeadingSection>A Cohesive Process</HeadingSection>
                            </div>
                            <div data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">
                                <ContentSection classNames="mt-4 sm:mt-6">During our Building Design process we help you with the nitty gritty details such as certification, town planning, engineering and any other consultants you may require on your project. This plays a huge part in the design process which is why we offer to coordinate or manage this on your behalf.</ContentSection>
                                <ContentSection classNames="mt-3.5 sm:mt-4">When it comes to builder's, we encourage you to use anyone you feel comfortable with but if you have not yet found the right fit, we also partner with selected builders and can organise an introduction for you. Whilst we are happy to provide you with introductions to builders, we do not manage the construction process.</ContentSection>
                                <ContentSection classNames="mt-3.5 sm:mt-4">This is your builders time to shine and they will be you point of call during the build! However, we will visit the site as needed to ensure the design is aligning with your vision, and remain available to you through to final hand over.</ContentSection>
                            </div>
                        </div>
                        <div className="w-full tablet:w-[432px] desktop:w-[502px]" data-aos="fade-left" data-aos-delay="200" data-aos-duration="1000">
                            <LazyImage
                                src="/images/building/cohesive.png"
                                placeholderImage="/images/compressed/building/cohesive.webp"
                                classNames="w-full object-cover phone:h-[414px] tablet:h-[546px] h-[640px] phone:mt-12 tablet:mt-[70px] desktop:mt-[42px]"
                                alt="cohesive"
                            />
                        </div>
                    </div>
                </div>
                <CalltoActionLayout>
                    <CalltoAction />
                </CalltoActionLayout>
            </PageLayouts>
        </Fragment>
    )
}

export default BuildingDesign