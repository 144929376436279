import { Fragment, useEffect, useState, useRef, useCallback } from "react"
import PageLayouts from "layouts/PageLayouts"
import { Link, useParams } from "react-router-dom"
import { projectList } from "data"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import 'swiper/css/navigation';
import { Navigation, Pagination } from "swiper";
import ProjectShowModal from "components/organism/ProjectShowModal"
import LazyImage from "components/LazyImage";
import clsx from "clsx";

const ProjectDetails = () => {
  const { projectId }: any = useParams()
  const currentProject = projectList[projectId - 1];
  const [isOpenLightGallery, setIsOpenLightGallery] = useState({
    isOpen: false,
    indexSlideOpen: 0
  });

  const swiper = useSwiper()

  const lightGallery = useRef<any>(null);
  const handleIsOpenLightGallery = (isOpen: boolean, indexSlideOpen: number) => {
    setIsOpenLightGallery((prevState) => ({
      ...prevState,
      isOpen: isOpen,
      indexSlideOpen: indexSlideOpen
    }));
  }

  useEffect(() => {
    isOpenLightGallery.isOpen ? document.body.style.overflow = "hidden" : document.body.style.overflow = ""
  }, [isOpenLightGallery])

  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  return (
    <Fragment>
      <PageLayouts title={`${currentProject.title}`} description="Transforming empty space into dream places">
        <div className="relative w-full phone:py-10 py-20 text-center phone:px-0 tablet:px-10 desktop:px-14">
          <h1 className="font-bold phone:mb-6 mb-10 text-[20px] leading-[28px] text-primary-main">{currentProject.title}</h1>
          <Swiper
            id="swiperImageProjectDetailCatalouge"
            loop={true}
            speed={1000}
            navigation={{
              nextEl: ".next",
              prevEl: ".prev"
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 16,
                centeredSlides: true
              },
              768: {
                slidesPerView: 1.25,
                spaceBetween: 16,
                centeredSlides: true
              },
              1280: {
                slidesPerView: 1.5,
                spaceBetween: 16,
                centeredSlides: true
              },
            }}
            pagination={{
              el: '.jail-app-left-side-fractions',
              type: 'custom',
              renderCustom: function (swiper, current, total) {
                return `0${current} of 0${total}`;
              }
            }}
            modules={[Pagination, Navigation]}
            className={clsx(
              "w-full relative",
              "h-[414.3px] md:h-[560px]"
            )}
          >
            <button onClick={() => swiper.slidePrev()} className="z-50">
              <img className={clsx(
                "w-8 h-8 md:w-[44px] md:h-[44px] absolute prev z-50",
                "top-1/2 left-2 md:left-6"
              )} src="/icons/general/previous.svg" alt="previous-icon" />
            </button>
            {currentProject.detailImage.map((item: any, index: number) => (
              <SwiperSlide className="phone:px-6 tablet:px-0 desktop:px-0">
                <LazyImage src={`/images/project/detailImage/${item}.jpg`} alt={item} classNames="cursor-pointer w-full h-[414.3px] md:h-[560px] mx-auto object-cover" placeholderImage={`/images/project/detailImage/compressed/${item}.webp`} onClick={() => handleIsOpenLightGallery(true, index)} />
              </SwiperSlide>
            ))}
            <button onClick={() => swiper.slideNext()} className="z-50">
              <img className={clsx(
                "w-8 h-8 md:w-[44px] md:h-[44px] absolute next",
                "top-1/2 right-2 md:right-6 z-50"
              )} src="/icons/general/next.svg" alt="next-icon" />
            </button>
          </Swiper>

          <div className="w-full flex items-center justify-between mt-6 md:mt-10 phone:px-6 tablet:px-0 desktop:px-0">
            <div className="">
              <Link to="/projects" className="flex items-center space-x-2">
                <img className="opacity-50" src="/icons/general/chevron-left.svg" alt="chevron-left" />
                <h3 className="font-medium text-sm text-primary-main text-opacity-50 leading-[19.6px]">Back to Projects</h3>
              </Link>
            </div>
            <div className="">
              <div className="jail-app-left-side-fractions font-normal text-sm text-primary-main leading-[19.6px]"></div>
            </div>
          </div>

          {/* lightGallery */}
          {isOpenLightGallery.isOpen &&
            <ProjectShowModal currentProject={currentProject} isOpenLightGallery={isOpenLightGallery} handleIsOpenLightGallery={handleIsOpenLightGallery} />
          }
        </div>
      </PageLayouts>
    </Fragment >
  )
}

export default ProjectDetails