import { Fragment } from 'react'
import PageLayouts from 'layouts/PageLayouts'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <Fragment>
            <PageLayouts title="404 Not Found" description="Interior Design Service">
                <div className="w-full py-20 phone:px-6 tablet:px-10 px-[140px] text-center">
                    <h1 className="text-[200px] leading-none font-bold text-primary-main">404</h1>
                    <h1 className="text-md font-normal text-primary-main text-opacity-50">Oops, page you visit is not found. Lets back to homepage</h1>
                    <Link to="/">
                        <button className="w-fit phone:w-full py-4 px-6 rounded-full bg-primary-main text-neutral-10 font-medium text-sm leading-[23.8px] mt-10">Back to Homepage</button>
                    </Link>
                </div>
            </PageLayouts>
        </Fragment>
    )
}

export default NotFound