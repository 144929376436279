import React, { Fragment } from "react"
import { IProjectCard } from "interfaces"
import { Link } from "react-router-dom"
import LazyImage from "components/LazyImage"

const ProjectCard: React.FC<IProjectCard> = ({ projectId, image, title }) => {
    return (
        <Fragment>
            <Link to={`/projects/${projectId}`}>
                <div className="w-full">
                    <LazyImage
                        src={`/images/project/${image}.png`} alt={title}
                        placeholderImage={`/images/compressed/project/${image}.webp`}
                        classNames="w-full phone:h-[280px] tablet:h-[172px] h-[200px] object-cover" />
                    <h1 className="text-md phone:mt-4 mt-6 font-medium text-primary-main leading-[22.4px] text-center]">{title}</h1>
                </div>
            </Link>
        </Fragment>
    )
}

export default ProjectCard