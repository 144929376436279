import { IProjectDetail } from "interfaces"

export const serviceList = [
  { id: 1, title: "Onsite Consultation", image: "onsite-consultation" },
  { id: 2, title: "Interior and Exterior Finishes Schedule", image: "interior" },
  { id: 3, title: "Joinery Design and Documentation", image: "joinery" },
  { id: 4, title: "3D Modelling and Rendering", image: "modelling" },
  { id: 5, title: "Residential Interior Design", image: "residential" },
  { id: 6, title: "Commercial Interior Design", image: "commercial" },
]

export const serviceListMobile = [
  { id: 1, title: "Onsite Consultation", image: "onsite-consultation" },
  { id: 2, title: "Joinery Design and Documentation", image: "joinery" },
  { id: 3, title: "Interior and Exterior Finishes Schedule", image: "interior" },
  { id: 4, title: "3D Modelling and Rendering", image: "modelling" },
  { id: 5, title: "Commercial Interior Design", image: "commercial" },
  { id: 6, title: "Residential Interior Design", image: "residential" },
]

export const designServiceList = [
  { id: 1, title: "Onsite Consultation", image: "onsite-consultation" },
  { id: 2, title: "Concept Design", image: "concept" },
  { id: 3, title: "Construction Drawings QBCC15102602", image: "construction" },
  { id: 4, title: "Management of External Consultants", image: "management" },
]

export const designServiceListMobile = [
  { id: 1, title: "Onsite Consultation", image: "onsite-consultation" },
  { id: 2, title: "Construction Drawings QBCC15102602", image: "construction" },
  { id: 3, title: "Concept Design", image: "concept" },
  { id: 4, title: "Management of External Consultants", image: "management" },
]

export const projectList: Array<IProjectDetail> = [
  {
    id: 1, image: "highgate-apartment", title: "Highgate Hill Apartment",
    detailImage: [
      "highgate/high1",
      "highgate/high2",
      "highgate/high3",
      "highgate/high4",
      "highgate/high5",
      "highgate/high6",
      "highgate/high7",
      "highgate/high8",
    ]
  },
  {
    id: 2, image: "highgate", title: "Highgate Hill Residence",
    detailImage: [
      "residence/highhill1",
      "residence/highhill2",
      "residence/highhill3",
      "residence/highhill4",
      "residence/highhill5",
    ]
  },
  {
    id: 3, image: "coorparoo", title: "Coorparoo Redidence",
    detailImage: [
      "coorparoo/coorparoo1",
      "coorparoo/coorparoo2",
      "coorparoo/coorparoo3",
      "coorparoo/coorparoo4",
      "coorparoo/coorparoo5",
      "coorparoo/coorparoo6",
      "coorparoo/coorparoo7",
    ]
  },
  {
    id: 4, image: "farm", title: "New Farm Residence",
    detailImage: [
      "farm/farm1",
      "farm/farm2",
      "farm/farm3",
      "farm/farm4",
      "farm/farm5",
      "farm/farm6",
    ]
  },
  {
    id: 5, image: "mount", title: "Mount Samson Residence",
    detailImage: [
      "mount/mount1",
      "mount/mount2",
      "mount/mount3",
      "mount/mount4",
      "mount/mount5",
      "mount/mount6",
      "mount/mount7",
      "mount/mount8",
      "mount/mount9",
    ]
  },
  {
    id: 6, image: "ascot", title: "Ascot Residence",
    detailImage: [
      "ascot/ascot1",
      "ascot/ascot2",
      "ascot/ascot3",
      "ascot/ascot4",
      "ascot/ascot5",
      "ascot/ascot6",
      "ascot/ascot7",
      "ascot/ascot8",
      "ascot/ascot9",
    ]
  },
  {
    id: 7, image: "pullenvale", title: "Pullenvale Residence",
    detailImage: [
      "pullenvale/pullenvale1",
      "pullenvale/pullenvale2",
      "pullenvale/pullenvale3",
      "pullenvale/pullenvale4",
      "pullenvale/pullenvale5",
      "pullenvale/pullenvale6",
      "pullenvale/pullenvale7",
      "pullenvale/pullenvale8",
      "pullenvale/pullenvale9",
      "pullenvale/pullenvale10",
      "pullenvale/pullenvale11",
    ]
  },
  {
    id: 8, image: "hamilton", title: "Hamilton Residence",
    detailImage: [
      "hamilton/hamilton1",
    ]
  },
  {
    id: 9, image: "princess", title: "Princess Polly HQ2",
    detailImage: [
      "princess/princess1",
      "princess/princess2",
      "princess/princess3",
      "princess/princess4",
      "princess/princess5",
      "princess/princess6",
      "princess/princess7",
      "princess/princess8",
      "princess/princess9",
    ]
  },
  {
    id: 10, image: "shine", title: "Shine Orthodontics",
    detailImage: [
      "shine/shine1",
      "shine/shine2",
      "shine/shine3",
      "shine/shine4",
      "shine/shine5",
    ]
  },
  {
    id: 11, image: "plae", title: "Plae HQ",
    detailImage: [
      "plae/plae1",
      "plae/plae2",
      "plae/plae3",
      "plae/plae4",
      "plae/plae5",
      "plae/plae6",
      "plae/plae7",
    ]
  }
]

export const socialList = [
  { id: 1, icons: "linkedin", url: 'https://www.linkedin.com/company/blended-interiors-and-design/' },
  { id: 2, icons: "instagram", url: 'https://www.instagram.com/blendedinteriorsanddesign/' },
  { id: 3, icons: "facebook", url: 'https://www.facebook.com/blendedinteriorsanddesign/?ref=py_c' },
]