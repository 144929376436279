import { Fragment } from "react"
import PageLayouts from "layouts/PageLayouts"
import ProfileLayout from "layouts/ProfileLayout"
import LazyImage from "components/LazyImage"
import { AuthorText, SectionTitle } from "components/atoms/Text"

const About = () => {
    return (
        <Fragment>
            <PageLayouts title="About" description="Transforming empty space into dream places">
                <div className="w-full phone:py-6 py-20 phone:px-6 tablet:px-10 desktop:px-[140px]">
                    <SectionTitle classNames="text-center">Meet Us</SectionTitle>
                </div>
                <ProfileLayout gradients>
                    <div data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">
                        <LazyImage
                            src="/images/about/deanna.png"
                            placeholderImage="/images/compressed/about/deanna.webp"
                            classNames="object-cover tablet:w-[432px] tablet:h-[546px] w-[502px] h-[636px] phone:h-[414px] phone:w-full" alt="deanna"
                        />
                    </div>
                    <div className="phone:w-full tablet:w-[450px] w-[564px] phone:mt-6 tablet:pr-[20px]">
                        <AuthorText>Deanna</AuthorText>
                        <p className="text-primary-main font-normal text-sm italic leading-[23.8px] opacity-50 mt-1">Building Designer / Interior Designer</p>
                        <p className="text-primary-main text-justify font-normal tablet:text-[16px] tablet:leading-[27.2px] phone:leading-[23.8px] phone:text-sm text-lg leading-[30.6px] mt-6">With seven years of passionate work in the design industry, Deanna is the heart and soul of Blended. Having recently started a family of her own, she brings a personal touch to designing the ideal home for hardworking Queenslanders. She knows the most important thing is to intimately understand a client’s brief and budget which is why we always make this our highest priority. Deanna’s vision is to build Blended into a nationwide company which delivers bespoke, high end designs and finishes along with a team of qualified designers who each have their own individual flair to make Blended stand out from the rest.</p>
                    </div>
                </ProfileLayout>
                <ProfileLayout reverse>
                    <div className="phone:w-full phone:mt-6 tablet:w-[450px] w-[564px] tablet:pl-[20px]">
                        <AuthorText>Sarah</AuthorText>
                        <p className="text-primary-main font-normal text-sm italic leading-[23.8px] opacity-50 mt-1">Interior Designer</p>
                        <p className="text-primary-main text-justify font-normal tablet:text-[16px] tablet:leading-[27.2px] text-lg phone:leading-[23.8px] phone:text-sm leading-[30.6px] mt-6">Sarah brings her highly organised and detailed mentality to everything Interior Design. Having study Commercial Interior Design at Torrens University and in addition to working across an array of high end residential and boutique commercial projects, Sarah has an excellent understanding of all the different facets of design. She loves to push the envelope whilst ensuring her clients wants and needs are met and exceeded. Highly skilled at 3D modelling and rendering, Sarah offers creativity and structure to each aspect of the design process.</p>
                    </div>
                    <div data-aos="fade-left" data-aos-delay="200" data-aos-duration="1000">
                        <LazyImage
                            src="/images/about/sarah.png"
                            placeholderImage="/images/compressed/about/sarah.webp"
                            classNames="object-cover phone:h-[414px] phone:w-full tablet:w-[432px] tablet:h-[546px] w-[502px] h-[636px]" alt="sarah"
                        />
                    </div>
                </ProfileLayout>
                <ProfileLayout gradients>
                    <div data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">
                        <LazyImage
                            src="/images/about/stephanie.png"
                            placeholderImage="/images/compressed/about/stephanie.webp"
                            classNames="object-cover phone:w-full phone:h-[414px] tablet:w-[432px] tablet:h-[546px] w-[502px] h-[636px]" alt="sarah"
                        />
                    </div>
                    <div className="tablet:w-[450px] w-[564px] phone:w-full phone:mt-6 tablet:pr-[20px]">
                        <AuthorText>Stephanie</AuthorText>
                        <p className="text-primary-main font-normal text-sm italic leading-[23.8px] opacity-50 mt-1">Interior Designer</p>
                        <p className="text-primary-main text-justify font-normal tablet:text-[16px] tablet:leading-[27.2px] phone:leading-[23.8px] phone:text-sm text-lg leading-[30.6px] mt-6">Stephanie is an Interior Designer who is passionate about creating innovative spaces that positively impact the end users well-being through the exploration of materiality and form. Skilled in 3D modelling combined with a creative niche for design, she brings a keen eye, and an energetic flare to each and every project. Stephanie graduated from Torrens University with a nomination for DIA’s GOTYA program. Her background consists of designing project home builds to luxury residential environments. Stephanie aims to create inspiring, unique and comfortable experiences to the way all occupants of the space live, work and interact through providing timeless and balanced interior solutions.</p>
                    </div>
                </ProfileLayout>
            </PageLayouts>
        </Fragment>
    )
}

export default About