import { Fragment } from "react"
import SocialMediaIcon from "components/atoms/SocialMediaIcons"
import { socialList } from "data"
import StarIcon from "components/atoms/StarIcon"

const Footer = () => {
    return (
        <Fragment>
            <div className="w-full flex phone:flex-col phone:items-start items-center justify-between phone:py-10 py-12 phone:px-6 tablet:px-10 px-14 bg-primary-main">
                <div className="">
                    <div className="flex items-center space-x-3">
                        <img src="/icons/phone.svg" className="w-[18px] h-5" alt="location" />
                        <p className="text-md font-normal text-neutral-10 leading-[27.2px]">0499 098 770</p>
                    </div>
                    <div className="flex items-center space-x-3 mt-2">
                        <img src="/icons/location.svg" className="w-[18px] h-5" alt="location" />
                        <p className="text-md font-normal text-neutral-10 leading-[27.2px] w-[75%] sm:w-auto">85 COMMERCIAL RD, TENERIFFE, Q 4005</p>
                    </div>
                </div>
                <div className="flex phone:flex-col phone:items-start items-center phone:space-x-0 space-x-6 phone:mt-10 mt-0">
                    <div className="flex items-center space-x-2">
                        {socialList.map((item: any) => (
                            <SocialMediaIcon key={item.id} icons={item} />
                        ))}
                    </div>
                    <div className="phone:mt-6 mt-0 flex items-center p-2 bg-socialmedia border-t-[6px] border-bordersocial">
                        <img src="/icons/google.svg" className="w-10 h-10" alt="google" />
                        <div className="ml-[7px]">
                            <p className="text-[13px] font-normal leading-[15.73px] text-neutral-10">Google Rating</p>
                            <div className="flex items-center gap-[6px] mt-[2px]">
                                <p className="text-[17px] font-normal leading-[20.57px] text-[#FBBC05]">5.0</p>
                                <div className="flex items-center space-x-[1.5px]">
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Footer