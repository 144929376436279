import { Fragment } from "react"
import { motion } from "framer-motion"
import PageLayouts from "layouts/PageLayouts"
import ProjectCard from "components/organism/Cards/ProjectCard"
import { projectList } from "data"

const Project = () => {
    const containerVariant = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1
        }
    }

    const fadeUpAnimate = (index: any) => {
        let duration = 0;
        if (index == 0) {
            duration = 100;
        } else if (index == 1) {
            duration = 250;
        } else if (index == 2) {
            duration = 350;
        } else if (index == 3) {
            duration = 450;
        } else if (index == 4) {
            duration = 550;
        } else if (index == 5) {
            duration = 650;
        } else if (index == 6) {
            duration = 750;
        } else if (index == 7) {
            duration = 850;
        } else if (index == 8) {
            duration = 950;
        }
        return duration;
    }

    return (
        <Fragment>
            <PageLayouts title="Project" description="Transforming empty space into dream places">
                <motion.div
                    initial="hidden"
                    variants={containerVariant}
                    animate="visible"
                    className="w-full phone:grid phone:grid-cols-1 phone:gap-y-10 tablet:gap-x-4 phone:py-10 flex flex-wrap gap-10 py-20 text-center phone:px-6 tablet:px-10 desktop:px-[140px] justify-center">
                    {projectList.map((item: any, index) => (
                        <>
                            <div className="hidden sm:block" data-aos="fade-up" data-aos-delay={fadeUpAnimate(index)} data-aos-duration="1000">
                                <ProjectCard key={item.id} projectId={item.id} image={item.image} title={item.title} />
                            </div>
                            <div className="sm:hidden" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                                <ProjectCard key={item.id} projectId={item.id} image={item.image} title={item.title} />
                            </div>
                        </>
                    ))}
                </motion.div>
            </PageLayouts>
        </Fragment>
    )
}

export default Project