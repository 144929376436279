import { Fragment } from "react"
import PageLayouts from "layouts/PageLayouts"
import CalltoActionLayout from "layouts/CalltoActionLayout"
import { ContentSection, SectionTitle, HeadingSection } from "components/atoms/Text"
import CalltoAction from "components/organism/CalltoAction"
import ServiceList from "components/moleculs/ServiceList"
import { serviceList, serviceListMobile } from "data"
import LazyImage from "components/LazyImage"

const Interiors = () => {
    return (
        <Fragment>
            <PageLayouts title="Interiors" description="Interior Design Service">
                <div className="phone:px-6 tablet:px-10 desktop:px-14 tablet:pb-[72px]" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                    <LazyImage
                        src="/images/interior/hero.png"
                        placeholderImage="/images/compressed/interior/hero.webp"
                        classNames="object-cover phone:h-[200px] desktop:h-[664px] tablet:h-[472px] w-full"
                        alt="front-hero"
                    />
                </div>
                <div className="w-full phone:pt-10 phone:pb-12 tablet:pt-0 tablet:pb-20 desktop:pt-[72px] desktop:pb-20 phone:px-6 tablet:px-10 desktop:px-[140px]">
                    <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                        <SectionTitle classNames="desktop:max-w-[572px] text-center tablet:max-w-[532px] phone:max-w-[256px] mx-auto tablet:text-[48px] tablet:leading-[62.4px]">Interior Design <br /> Service</SectionTitle>
                    </div>
                    <div className="my-14 sm:grid gap-x-20 grid-cols-2 hidden">
                        {serviceList.map((item: any) => (
                            <ServiceList id={item.id} title={item.title} pages="interior" image={item.image} />
                        ))}
                    </div>
                    <div className="my-14 mt-6 mb-10 grid grid-cols-1 gap-x-0 sm:hidden divide-y divide-neutral-30 sm:divide-y-0">
                        {serviceListMobile.map((item: any) => (
                            <ServiceList id={item.id} title={item.title} pages="interior" image={item.image} />
                        ))}
                    </div>
                    {/* Initial Design */}
                    <div className="w-full flex flex-col phone:flex-col-reverse md:flex-row justify-between tablet:pt-20 desktop:pt-20">
                        <div className="w-full tablet:w-[432px] desktop:w-[502px] phone:mt-6">
                            <div data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">
                                <HeadingSection>Initial Design Consultation <br />(2 Hour Duration)</HeadingSection>
                            </div>
                            <div data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">
                                <ContentSection classNames="mt-4 sm:mt-6">How can we help you? During your two hour design consultation we will explore your ideas and concerns to help identify your overall design goals. This is your time to ask questions to ensure you feel confident in your project direction and desired outcome. Our qualified designers will be able to provide you with advice and guidance and potential next steps with our studio.</ContentSection>
                                <ContentSection classNames="mt-3.5 sm:mt-4">Design Consultations start from $440+GST</ContentSection>
                            </div>
                        </div>
                        <div className="w-full tablet:w-[432px] desktop:w-[502px]" data-aos="fade-left" data-aos-delay="200" data-aos-duration="1000">
                            <LazyImage
                                src="/images/interior/initial-design.png"
                                placeholderImage="/images/compressed/interior/initial-design.webp"
                                classNames="w-full phone:h-[414px] tablet:h-[546px] h-[636px] phone:mt-10 md:mt-0 object-cover"
                                alt="front-hero"
                            />
                        </div>
                    </div>
                    {/* Custom Interior */}
                    <div className="w-full flex flex-col md:flex-row justify-between tablet:mt-20">
                        <div className="w-full tablet:w-[432px] desktop:w-[502px]" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">
                            <LazyImage
                                src="/images/interior/custom-interior.jpg"
                                placeholderImage="/images/compressed/interior/custom-interior.webp"
                                classNames="w-full phone:h-[414px] tablet:h-[777px] h-[876px] mt-12 tablet:-mt-[120px] desktop:-mt-[64px]"
                                alt="front-hero"
                            />
                        </div>
                        <div className="w-full tablet:w-[432px] desktop:w-[502px] phone:pt-6 tablet:pt-[10px] desktop:pt-[100px]">
                            <div data-aos="fade-left" data-aos-delay="100" data-aos-duration="1000">
                                <HeadingSection>Custom Interior and Exterior Design</HeadingSection>
                            </div>
                            <div data-aos="fade-left" data-aos-delay="200" data-aos-duration="1000">
                                <ContentSection classNames="mt-4 sm:mt-6">Perfect for large scale renovations, new builds and boutique office fitouts this process is the full documentation package which provides a high level of detail to ensure that your project is ready for tendering with your preferred builder.</ContentSection>
                                <ContentSection classNames="mt-3.5 sm:mt-4">We will work with you to explore your plans in finer detail to develop your design and ensure all bases are covered so you can enjoy the process with minimal stress.</ContentSection>
                                <ContentSection classNames="mt-3.5 sm:mt-4">This tailored service is best suited for larger scale projects starting from $300K + due to the level of detailing required. An Initial Design Consultation is required to Commence this process.</ContentSection>
                            </div>
                        </div>
                    </div>
                    {/* Interior Styling and Decorating */}
                    <div className="w-full flex flex-col phone:flex-col-reverse md:flex-row justify-between desktop:pb-20">
                        <div className="w-full tablet:w-[432px] desktop:w-[502px] phone:pt-6 tablet:pt-[90px] desktop:pt-[100px]">
                            <div data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">
                                <HeadingSection classNames="tablet:pr-10 desktop:p-0">Interior Decorating and Styling</HeadingSection>
                            </div>
                            <div data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">
                                <ContentSection classNames="mt-4 sm:mt-6">Looking to fit out your home or office space? We offer a full furniture and decor service where we can source and supply you with new pieces for your project.</ContentSection>
                                <ContentSection classNames="mt-3.5 sm:mt-4">Our exclusive relationships with wholesale suppliers helps us to access high quality and on trend pieces for your space. We work with selected suppliers that offer premium customer service with white glove delivery that ensures an easy and smooth installation process for our clients. An Initial Design Consultation is required to start this process.</ContentSection>
                            </div>
                        </div>
                        <div className="w-full tablet:w-[432px] desktop:w-[502px]" data-aos="fade-left" data-aos-delay="200" data-aos-duration="1000">
                            <LazyImage
                                src="/images/interior/decorating.jpg"
                                placeholderImage="/images/compressed/interior/decorating.webp"
                                classNames="w-full phone:h-[414px] tablet:h-[546px] h-[636px] phone:mt-12 mt-10 tablet:-mt-[70px] desktop:-mt-[95px]"
                                alt="decorating"
                            />
                        </div>
                    </div>
                </div>
                <CalltoActionLayout>
                    <CalltoAction />
                </CalltoActionLayout>
            </PageLayouts>
        </Fragment>
    )
}

export default Interiors