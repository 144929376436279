import clsx from "clsx";
import { Fragment, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Transition } from "@headlessui/react";

const Navbar = () => {
    const currentLocation = useLocation().pathname;

    const [isOpen, setIsOpen] = useState(false);

    const navigation = [
        { id: 1, title: "Interior Design", path: "/interior-design" },
        { id: 2, title: "Building Design", path: "/building-design" },
        { id: 3, title: "Projects", path: "/projects" },
        { id: 4, title: "About", path: "/about" },
        { id: 5, title: "Contact", path: "/contact" }
    ];
    return (
        <Fragment>
            <nav className="w-full bg-neutral-10">
                <div className="tablet:px-10 desktop:px-14 phone:p-6 tablet:py-[22px] desktop:py-[22px] w-full flex items-center justify-between">
                    <div className="w-full flex items-center justify-between">
                        <div className="hidden phone:block">
                            <Link to="/">
                                <img src="/icons/general/logo.svg" width="64px" height="40px" alt="blended-interior-logo" />
                            </Link>
                        </div>
                        <div className="w-full hidden mx-auto md:flex justify-between items-center">
                            <div className="flex items-center space-x-12">
                                {navigation.slice(0, 2).map((item) => (
                                    <NavLink to={item.path} className={clsx(
                                        "text-sm tablet:text-[15px] leading-[24px] desktop:text-[15px] tracking-text",
                                        currentLocation === item.path ? "text-primary-main font-semibold" : "text-neutral-100"
                                    )}>{item.title}</NavLink>
                                ))}
                            </div>
                            <Link to="/">
                                <img src="/icons/general/logo.svg" width="89px" height="56px" alt="blended-interior-logo" />
                            </Link>
                            <div className="flex items-center space-x-12">
                                {navigation.slice(2, 5).map((item) => (
                                    <NavLink to={item.path} className={clsx(
                                        "text-sm tablet:text-[15px] leading-[24px] desktop:text-[15px] tracking-text",
                                        currentLocation === item.path ? "text-primary-main font-semibold" : "text-neutral-100"
                                    )}>{item.title}</NavLink>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex tablet:w-fit md:space-x-4 justify-between">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            type="button"
                            className="inline-flex md:hidden items-center justify-center rounded-md text-primary-main focus:outline-none "
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Open main menu</span>
                            {!isOpen
                                ? (
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21 7.75H3C2.59 7.75 2.25 7.41 2.25 7C2.25 6.59 2.59 6.25 3 6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75Z" fill="#12203F" />
                                        <path d="M21 12.75H3C2.59 12.75 2.25 12.41 2.25 12C2.25 11.59 2.59 11.25 3 11.25H21C21.41 11.25 21.75 11.59 21.75 12C21.75 12.41 21.41 12.75 21 12.75Z" fill="#12203F" />
                                        <path d="M21 17.75H3C2.59 17.75 2.25 17.41 2.25 17C2.25 16.59 2.59 16.25 3 16.25H21C21.41 16.25 21.75 16.59 21.75 17C21.75 17.41 21.41 17.75 21 17.75Z" fill="#12203F" />
                                    </svg>
                                )
                                : (
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 6L6 18M6 6L18 18" stroke="#12203F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                )}
                        </button>
                    </div>
                </div>

                <Transition
                    show={isOpen}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    {(ref: any) => (
                        <div className="desktop:hidden phone:h-[100vh]" id="mobile-menu">
                            <div ref={ref} className="p-6 gap-4 flex flex-col mt-0 mb-0">
                                {navigation.map((item) => (
                                    <NavLink to={item.path} className={clsx(
                                        "text-lg leading-[27px]",
                                        "px-4 hover:bg-neutral-20 py-[11px] hover:cursor-pointer",
                                        currentLocation === item.path ? "text-primary-main font-medium" : "text-primary-main opacity-50"
                                    )}>{item.title}</NavLink>
                                ))}
                            </div>
                        </div>
                    )}
                </Transition>
            </nav>
        </Fragment >
    );
};

export default Navbar;