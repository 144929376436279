import { ReactNode } from "react"
import clsx from "clsx"

const ProfileLayout = (
    { children, classNames, gradients, reverse }:
        { children: ReactNode, classNames?: string, gradients?: boolean, reverse?: boolean }) => {
    return (
        <div className={clsx(
            "w-full flex justify-between items-center phone:py-10 py-20 phone:px-6 tablet:px-10 desktop:px-[140px]",
            gradients ? "gradients" : "bg-neutral-10",
            reverse ? "phone:flex-col-reverse" : "phone:flex-col",
            classNames
        )}>
            {children}
        </div>
    )
}

export default ProfileLayout