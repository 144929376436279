import React, { Fragment, useEffect, useRef, useState } from "react"
import { motion } from "framer-motion"
import { SectionTitle, Description } from "components/atoms/Text"
import { fadeInBottomVariant } from "animations/variants"

const CalltoAction: React.FC = () => {
    const openCalendly = () => {
        window.open("https://calendly.com/blendedinteriors");
    }
    const calltoactionRef = useRef<HTMLElement | any>();
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: "0px",
        };

        const checkIntersections = (entries: any) => {
            entries.forEach((entry: any) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    entry.target.classList.add("visible");
                }
            });
        };

        if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(calltoactionRef.current);
            return () => newIO.disconnect();
        }
    }, [isVisible]);
    return (
        <Fragment>
            <div ref={calltoactionRef} className="call-to-action relative flex flex-col items-center justify-center tablet:py-[60px] py-[120px] phone:mb-10 phone:py-10 mb-20 text-center phone:px-4 px-0">
                <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" className="flex items-center justify-center">
                    <SectionTitle classNames="!text-neutral-10">Let's team up!</SectionTitle>
                </div>
                <p data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000" className="!text-neutral-10 phone:pt-4 tablet:pt-5 pt-6 pb-10 phone:pb-6 phone:max-w-full max-w-[674px] mx-auto desktop:leading-[30.6px] tablet:text-[16px] desktop:text-lg font-normal leading-text tablet:leading-[27.2px] phone:text-[14px] phone:leading-[24px] ">
                    Our interior and building designers would love to help you transform your next project! Book a meeting with us below. We can travel to you or meet at our office.
                </p>
                <button
                    data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000"
                    onClick={openCalendly}
                    className="phone:py-3 py-4 px-6 rounded-full bg-neutral-10 text-center text-sm text-neutral-100 font-medium leading-small-text">
                    Book Online Now
                </button>
            </div>
        </Fragment>
    )
}

export default CalltoAction